import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Головна" />
    <h1>Благодійний фонд “Імпульс Україна”</h1>
    <h2>Місія</h2>
    <h4>Провідна допомога та підтримка-право кожної людини</h4>
    <p>Цією місією ми хочемо сказати що провідна допомога це сучасна толерантна, якісна соціальна, психосоціальна, юридична, психологічна підтримка на яку має право кожен не залежно від своїх фінансових можливостей, сексуальної орієнтації чи гендерної ідентичності</p>
    <h2>Напрямки роботи</h2>
    <h4 className="bold">Основним напрямом роботи нашої організації є ЛГБТ спільнотою</h4>
    <ul>
      <li>Лесбійки, Бісексуальні жінки, ЖСЖ</li>
      <li>Геї, Бісексуальні хлопці, ЧСЧ</li>
      <li>Трансгендерна та небінарні персони</li>
      <li>Секс працівники та працівниці</li>
      <li>Хімсекс</li>
      <li>Люди з обмеженими можливостями</li>
      <li>Переселенці</li>
      <li>Розвиток та підтримка творчого потенціалу спільноти музичних колективів, театральних</li>
    </ul>

    <h2>Команда</h2>
    <p>Наша команда побудована таким чином що за комунікацію відповідають координатори які є рівними до окремих підгруп і мають на них вихід. У своєму підпорядкуванні вони можуть мати волонтерів що допомагатимуть їм у комунікації чи реалізації проєкт. З волонтерами укладаються угоди. Також наша команда має рівного лікаря, а також базу з контактами дружніх лікарів</p>
    <p><b>Назар Токар</b> - Директор організації, параюрист та активіст відкритий гей</p>
    <p><b>Ткачук Нікіта</b> - координатор комунікації зі спільнотою (геї, бі, ЧСЧ) активіст, відкритий гей</p>
    <p><b>Ян</b> - небінарний гендерквір персона координатор з комунікації з трансгендерними персонами</p>
    <p><b>Микола Сухов</b> – дружній лікар</p>
    <p><b>Форня Максим</b> – координатор з організації масових заходів</p>

    <h2>Історія заснування організації</h2>
    <p>Історія нашої команди почалась з 2019 року  як ініціативна група яка хотіла реалізовувати заходи для спільноти. Проте з кожним місяцем, днем наша команда фіксувала правопорушення, невдалі камінг-аути, дискримінацію навіть серед тих сфер які мають бути дружніми до спільноти і не лише ЛГБТК. Тому наша команда вирішила що ініціативна група повинна трансформуватись в організацію що зможе допомагати та підтримувати спільноту та їх батьків.</p>
    
    <h2>Проблеми в регіоні</h2>
    <p>Через високий рівень релігійності та консервативності в нашому регіоні в нас дуже високий рівень закритості спільноти само стигматизації, гомофобії, дискримінації. Тому наша організація хоче робити послуги якіснішими та доступнішими, що спрямовані перш за все на спільноту та на запит  в нашому регіоні. Особливими проблемами є дискримінація в медичних закладах та правоохоронних органах…</p>
    
    <h2>Партнерські організації</h2>
    <p>Нашим основним партнером є <b>Благодійний фонд “Аванте”</b></p>

    {/* <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link> <br />
      <Link to="/using-ssr">Go to "Using SSR"</Link> <br />
      <Link to="/using-dsg">Go to "Using DSG"</Link>
    </p> */}
  </Layout>
)

export default IndexPage
